.op-principal
  display: inline-flex
  align-items: center

  &--avatar
    flex-grow: 0
    flex-shrink: 0

  &--name
    @include text-shortener
    flex-grow: 1
    flex-shrink: 1
    min-width: 0 // See: https://css-tricks.com/flexbox-truncated-text/
    margin-left: 10px

  &--multi-line
    display: block
    margin-bottom: 2px

  &_hide-name-on-mobile &
    @media screen and (max-width: 680px)
      &--name
        display: none
